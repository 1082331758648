import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About`}</h1>
    <p>{`Passionate about optimizing and producing high-quality, scalable code that delivers delightful user experiences.
Innovative hands-on collaborator excited to continue learning within technology to resolve complex problems and contribute to corporate growth. When not coding you can find me on my bike with my dog, watching some ‘how-to’ video, gardening, or experimenting in the kitchen with my family. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      