import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get In Touch`}</h2>
    <p><a parentName="p" {...{
        "href": "mailto:earthctzn1@gmail.com"
      }}>{`Email`}</a>{` | `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/caleb-r/"
      }}>{`LinkedIn`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      