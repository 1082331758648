import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Caleb R.`}</h1>
    <h3>{`Full Stack Developer writing clean, elegant code in Ruby | Rails | Javascript | CSS | HTML | React | Redux`}</h3>
    <h3><a parentName="h3" {...{
        "href": "https://docs.google.com/document/d/1Jd7VEVAfa-pF8sHIUlHIL9xVrnH0NTNCPRZk7fBV91M/edit?usp=sharing"
      }}>{`Resume`}</a>{` | `}<a parentName="h3" {...{
        "href": "https://www.linkedin.com/in/caleb-r/"
      }}>{`LinkedIn`}</a>{` | `}<a parentName="h3" {...{
        "href": "https://medium.com/@earthctzn"
      }}>{`Blog`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "mailto:earthctzn1@gmail.com"
      }}>{`Email Caleb`}</a></h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      