import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="CraftBrew" link="https://craft-brew.herokuapp.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
    A web app for locating and reviewing breweries that consumes JSON data from external APIs like GoogleMaps and OpenBreweryDB. Built with React.js (ES6), StyledComponents, CSS3 and Ruby on Rails as an API.
   <br></br><a href="https://github.com/earthctzn/craft-frontend">Front End Repo</a> | <a href="https://github.com/earthctzn/craft-backend">Back End Repo</a>
    </ProjectCard>
    <ProjectCard title="Taskwiz" link="https://taskwiz.herokuapp.com/" bg="linear-gradient(to right, #9e1eed 0%, #35f0e3 100%)" mdxType="ProjectCard">
    A Ruby on Rails application that manages related data through complex forms and RESTful routes.
    <br></br><a href="https://github.com/earthctzn/taskwiz">Repo</a>
    </ProjectCard> 
    <ProjectCard title="Staticshooter" link="https://staticshooter.herokuapp.com/" bg="linear-gradient(to right, #f197fc 0%, #ed1e78 100%)" mdxType="ProjectCard">
    A JavaScript SPA browser game based on Galaga. Built using HTML5, CSS3, and Javascript for the front end and Ruby on Rails as an API for the back end.
    <br></br><a href="https://github.com/earthctzn/static-shooter-frontend">Front End Repo</a> | <a href="https://github.com/earthctzn/static-shooter-backend">Back End Repo</a>
    </ProjectCard>
    <ProjectCard title="TravelTracker" link="https://traveler-stories.herokuapp.com/" bg="linear-gradient(to right, #009244 0%, #fcee21 100%)" mdxType="ProjectCard">
    A Content Management System created with Sinatra using MVC architecture that provides full CRUD functionality. Logged in users can share stories of places they have visited.
    <br></br><a href="https://github.com/earthctzn/TravelTracker">Repo</a>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      